<template>
    <div class="invitation-wrapper">

        <div class="register-group">
            <div v-if="isLoading(['GetUserInvitation', 'HandleUserRegister'])" class="spinner-flex"><Spinner/></div>
            <h1>Register</h1>

            <form class="register-form" @submit.prevent="HandleRegister" method="post" action="">
                <Input name="Name" v-model="item.name" :error="errors.name" placeholder="Enter name"/>
                <Input name="Last name" v-model="item.lastname" :error="errors.lastname" placeholder="Enter last name"/>
                <Input name="Display name" v-model="item.displayName" :error="errors.displayName" placeholder="Enter display name"/>
                <InputDropDownDatepicker v-model="item.dob" :error="errors.dob" placeholder="Select date of birth" name="Date of birth"/>
                
                <Input v-model="item.email" :error="errors.email" name="Email address" inputName="email" placeholder="Email address" :disabled="true"/>
                <Input v-model="item.password" :error="errors.password" name="Password" inputName="password" type="password" placeholder="Password"/>
                <Input v-model="item.repeatPassword" :error="errors.repeatPassword" name="Repeat password" inputName="password" type="password" placeholder="Repeat password"/>
                <Button type="submit" text="Register" icon="fa-solid fa-arrow-right-to-bracket" />
            </form>

        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                item: {},
                errors: {}
            }
        },
        computed: {
            invitationId() {
                return this.$route.params.id;
            }
        },
        methods: {
            getUserInvitation() {
                this.ajax('GetUserInvitation', {
                    url: `/user/invitation/${this.invitationId}`,
                    method: 'GET',
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        this.$router.push({ path: '/' });
                        return;
                    }
                    delete body.password;
                    this.item = body;
                });
            },
            HandleRegister() {
                this.ajax('HandleUserRegister', {
                    url: `/user/invitation/${this.invitationId}`,
                    method: 'POST',
                    data: this.item
                }, (err, body) => {
                    
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$ShowAlert("You have been successfully registered.");
                    this.$router.push({ path: '/' });
                });
            }
        },
        mounted() {
            this.getUserInvitation();
        }
    }
</script>

<style lang="scss" scoped>
.invitation-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.register-group {
    max-width: 450px;
    width: 100%;
    background: $themeColor1;
    padding: 30px;
    border-radius: 10px;
    display: grid;
    row-gap: 20px;
    position: relative;
    overflow: hidden;
}


.register-form {
    display: grid;
    row-gap: 15px;
    justify-items: start;
}
</style>